<template>
  <div class="transfer-usdt">
    <div class="tf-box">
      <el-card class="box-card">
        <div class="tf-tip-box">
          <p class="tip-p">· {{ $t("TransferUSDT.tipsp1") }}</p>
          <p class="tip-p">· {{ $t("TransferUSDT.tipsp2") }}</p>
          <p class="tip-p">· {{ $t("TransferUSDT.tipsp3") }}</p>
          <p class="tip-p" style="color:red;font-weight:bold">· {{ $t("TransferUSDT.tipsp4") }}</p>
          <p class="tip-p">· {{ $t("TransferUSDT.tipsp5") }}</p>
          <p class="tip-p">· {{ $t("TransferUSDT.tipsp6") }}</p>
          <p class="tip-p" style="color:red;font-weight:bold">· {{ $t("TransferUSDT.tipsp7") }}</p>
        </div>

        <div style="margin:10px 0">
          <el-alert type="warning" :title="$t('TransferUSDT.PayAfterTip')"></el-alert>
        </div>

        <div class="tf-ma-box">
          <div class="ma">
            <vueQr :text="eSrc"></vueQr>
          </div>
          <div class="copy-box">
            <div class="cwrp">
              <div class="ctitle">{{ $t("TransferUSDT.TransferAddress") }}</div>
              <div class="caddr">
                <!-- <span>{{ eSrc }}</span> -->
                <span style="font-weight:bold">{{eSrcBefore}}</span>
                <span>{{eSrcCenter}}</span>
                <span style="font-weight:bold">{{eSrcAfter}}</span>
                <!-- <i class="el-icon-document-copy ic" @click="getCopy(eSrc)"></i> -->
              </div>
            </div>
           <div style="margin-top:.88rem;text-align:center;font-weight:bold">
                <el-alert
            :closable="false"
            :title="$t('TransferUSDT.BottomTip')"
            type="info"
            description=""
            center
            show-icon
          >
          </el-alert>
           </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { getTronAddress } from "../../api/http";
export default {
  components: { vueQr },
  data() {
    return {
      eSrc: "",
      eSrcBefore: '',
      eSrcAfter: '',
      eSrcCenter: ''
    };
  },
  created() {
    this.getAddr();
  },
  methods: {
    // 复制
    getCopy(data) {
      let input = document.createElement("input"); // js创建一个input输入框
      input.value = data; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        type: "success",
        message: "Copied",
      });
    },
    getAddr() {
      let obj = {
        type: "get_tron_address",
        uid: localStorage.getItem("uid"),
      };
      getTronAddress(obj).then((res) => {
        console.log("res==>", res);
        this.eSrc = res.data;
        this.eSrcBefore = res.data.slice(0,6)
        this.eSrcCenter = res.data.slice(6,-6)
        this.eSrcAfter = res.data.slice(-6)
      });
    },
  },
};
</script>

<style scoped>
.transfer-usdt {
  padding-left: 2.6rem;
}
.tf-box {
  /* height: 320px; */
  width: 50%;
  margin: 1rem auto;
}
.caddr{
    color: #909399;
}
.tf-tip-box {
  background-color: #f4f4f5;
  color: #909399;
  border-radius: 5px;
  font-weight: bold;
}
.tip-p {
  padding: 0.3rem 0.5rem;
  font-size: 13px;
}
.copy-box {
  margin-top: 0.5rem;
}
.tf-ma-box {
  text-align: center;
}
.ctitle {
  font-weight: bold;
  margin-bottom: 0.3rem;
}
.ic {
  font-size: 22px;
  cursor: pointer;
  vertical-align: bottom;
}
p {
  margin: 0;
}
</style>